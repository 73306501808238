//Colors
$dark-grey: #333333;
$grey: #666666;
$blue: #1c88bf;
$dark-blue:   #0c66cd;
$light-black: #333745;
$off-white: #f7f7f7;
$light-black: #20222b;
$purple: #8357c5;
$light-blue: #e0eeff;
$off-black: #191919;
$green: #098201;
$yellow: #d6dd02;
$orange: #dd7702;
$red: #bf0121;

//Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

//Sizes
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 46rem;

//Animations
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}