.button {
    background: $orange;
    border: none;
    border-bottom: .6rem solid darken($orange, 10%);
    color: white;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    margin-bottom: $m-size;
    padding: $s-size;
}

.button--modal {
    margin-bottom: $s-size;
}

.button--reset {
    margin-bottom: none;
    padding: none;
    height: 7.5rem;
}

.button--header {
    margin-top: 0.5%;
    padding: none;
}

.button__link {
    background: none;
    border: none;
    color: $orange;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    padding: $s-size;
    font-weight: bold;
    &:hover {
        text-decoration: underline;
    }
}


