.ReactModalPortal > div {
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal {
    background: $off-black;
    color: white;
    max-width: 30rem;
    outline: none;
    padding: $l-size;
    text-align: center;
}
 
.modal__title {
    margin: 0 0 $m-size 0;
}

.modal__body {
    font-size: 2rem;
    font-weight: 300;
    margin: 0 0 $s-size 0;
    word-break: break-all;
}