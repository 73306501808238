.section {
    @media (min-width: $desktop-breakpoint) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20%;
    }
}

.section--project {
    margin-top: 7.5%;
}
.section__resume {
    @media (min-width: $desktop-breakpoint) {
        display: flex;
        align-items: center;
        height: 90vh;
        justify-content: space-between;
    }
}