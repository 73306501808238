.textDefault {
    color: $off-white;
}

.textDefault--correct {
    color: $green;
}

.textDefault--incorrect {
    color: $red;
    font-style: italic;
}

.textDefault--current {
    color: $orange;
    font-weight: bold;
    font-size: 45px;
    cursor: pointer;
}

.textStats {
    padding: $m-size;
    font-weight: bold;
    background-color: $off-black;
}