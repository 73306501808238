* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    background: $light-black;
    background-size: cover;
}

body {
    color: $off-white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: $m-size;
    line-height: 1.6;
}

button {
    cursor: pointer;
}

button:disabled {
   cursor: default; 
}

img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    @media (min-width: $desktop-breakpoint) {
        margin: $l-size;
    }
 }

.is-active {
    font-weight: bold;
}