.page-header {
    color: $orange;    
    animation: fadein 2s;    
    font-size: 50px; 
}

.page-header__big {
    font-size: 68px;
    color: $orange;  
    animation: fadein 2s;
    text-align: center;
    @media (min-width: $desktop-breakpoint) {
        font-size: 120px;
        text-align: left;
    }
}


