.text-input {
    border: 1px solid #cacccd;
    font-size: $font-size-large;
    font-weight: 300;
    height: 50px;
    padding: $s-size;
}

.text-input--typingtest {
    max-width: 100rem;
    font-size: 27px;
    @media (min-width: $desktop-breakpoint) {
        width: 100rem;
        height: 7.5rem;
    }
}

.text-input--username {
    text-align: center;
    margin-bottom: 10%;
}

.select {
    @extend .text-input;
}